import styled from '@emotion/styled'

export const Root = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: theme.borderRadius.r6,
  padding: theme.spacing.s8,
  marginTop: theme.spacing.s12,
  background: theme.color['surface/tertiary'],
  width: '100%',
  textAlign: 'left',

  [`${theme.media.md}`]: {
    borderRadius: theme.borderRadius.r10,
    padding: theme.spacing.s12,
  },
}))

export const Delimiter = styled.hr(({ theme }) => ({
  border: `1px solid ${theme.color['surface/line']}`,
  marginTop: theme.spacing.s8,
  marginBottom: theme.spacing.s8,
  borderRadius: 1,
  width: '100%',

  [`${theme.media.md}`]: {
    marginTop: theme.spacing.s10,
    marginBottom: theme.spacing.s10,
  },
}))
