import { ValidationSchema, checkValid } from '@/utils/validation-resolver'
import { FormValues } from './models'
import { amountStringToNumber } from '@/utils'
import { currency } from '@/utils/currency'

export const exchangePointsFormSchema =
  (props: { currentBalance: number; minSumExchange: number }): ValidationSchema<FormValues> =>
  (data, errors) => {
    errors.amount = checkValid(data.amount, [
      (amount) => {
        if (!amount) {
          return ''
        }

        const formatAmount = amountStringToNumber(amount)

        if (formatAmount < props.minSumExchange) {
          const formatMinSumExchange = currency(props.minSumExchange, 'RUB').format()

          const formatCurrentBalance = currency(props.currentBalance, 'RUB').format()

          return `Можете вернуть от ${formatMinSumExchange} до ${formatCurrentBalance}`
        }

        if (formatAmount > props.currentBalance) {
          return 'Введённая сумма превышает доступный остаток'
        }

        return ''
      },
    ])

    return errors
  }
