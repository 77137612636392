import { MutateOptions, useMutation } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { adapter } from './adapters/adapter'
import { PointsExchangeModel } from './PointsExchangeModel'

export const pointsExchange: PointsExchangeModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.partners.pointsExchange,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    PointsExchangeModel.ResponseData,
    void,
    PointsExchangeModel.Props,
    PointsExchangeModel.RequestHeaders,
    PointsExchangeModel.ErrorResponse
  >({
    url: path,
    data: {
      maskCardNumber: props.maskCardNumber,
      moneyToCard: props.moneyToCard,
      exchangeSum: props.exchangeSum,
    },
    responseAdapter: adapter,
    headers: null,
    control,
  })

  return response
}

export function usePointsExchangeMutation(
  options?: MutateOptions<
    PointsExchangeModel.Response,
    PointsExchangeModel.ErrorResponse,
    PointsExchangeModel.Props
  >
) {
  return useMutation(pointsExchange, options)
}
