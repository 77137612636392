import styled from '@emotion/styled'
import { ButtonBase, CircularProgress } from '@platform-ui/components'
import { ChevronRightIcon } from '@platform-ui/icons'

export const Root = styled(ButtonBase)(({ theme }) => ({
  color: theme.color['text/main'],
  ...theme.typography['headline/14'],
  fontWeight: 600,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.color['background/main'],
  width: '100%',
  padding: 0,
  marginTop: theme.spacing.s16,

  [`${theme.media.md}`]: {
    ...theme.typography['headline/20'],
    fontWeight: 700,
    marginTop: theme.spacing.s12,
  },
}))

export const Body = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing.s6,
  textAlign: 'left',

  [`${theme.media.md}`]: {
    gap: theme.spacing.s8,
  },
}))

export const IconWrapper = styled.div(({ theme, color }) => ({
  padding: theme.spacing.s4,
  width: 40,
  height: 40,
  borderRadius: theme.borderRadius.r16,
  backgroundColor: theme.color[color],
}))

export const ChevronRight = styled(ChevronRightIcon)(({ theme }) => ({
  width: theme.iconSize.s12,
  height: theme.iconSize.s12,

  [`${theme.media.md}`]: {
    width: theme.iconSize.s16,
    height: theme.iconSize.s16,
  },
}))

export const Loader = styled(CircularProgress)(({ theme }) => ({
  width: theme.iconSize.s12,
  height: theme.iconSize.s12,

  [`${theme.media.md}`]: {
    width: theme.iconSize.s16,
    height: theme.iconSize.s16,
  },
}))
