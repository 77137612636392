import { SubmitHandler, useForm } from 'react-hook-form'
import { FormValues } from './models'
import { useValidationResolver } from '@/utils/validation-resolver'
import { exchangePointsFormSchema } from './exchangePointsFormSchema'
import { usePointsExchangeMutation } from '@/api/partners/pointsExchange'
import { CardsModel } from '@/api/partners/cards/CardsModel'
import { amountStringToNumber } from '@/utils'

export const useFormState = (
  currentBalance: number,
  maskCardNumberByDefault: string,
  cards: CardsModel.CardForExchange[],
  minSumExchange: number
) => {
  const resolver = useValidationResolver<FormValues>(
    exchangePointsFormSchema({ currentBalance, minSumExchange })
  )

  const pointsExchangeMutation = usePointsExchangeMutation()

  const form = useForm<FormValues>({
    resolver,
    mode: 'onChange',
    defaultValues: { amount: '', maskCardNumber: maskCardNumberByDefault },
  })

  const handleValidSubmit: SubmitHandler<FormValues> = async (formValues) => {
    const amountNumber = amountStringToNumber(formValues.amount)

    const selectedCard = cards.find((card) => card.maskCardNumber === formValues.maskCardNumber)

    pointsExchangeMutation.mutate({
      maskCardNumber: formValues.maskCardNumber,
      moneyToCard: selectedCard?.cardId || '',
      exchangeSum: amountNumber,
    })
  }

  return {
    form,
    pointsExchangeMutation,
    handleValidSubmit,
  }
}
