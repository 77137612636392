import React from 'react'
import { observer } from 'mobx-react-lite'
import { Controller, SubmitHandler, UseFormReturn, useFormContext } from 'react-hook-form'
import { FieldControl } from '@platform-ui/components/FieldControl'
import { InputMask } from '@platform-ui/components/InputMask'
import { SuccessIcon } from '@platform-ui/icons'
import { Button } from '@platform-ui/components'
import { currency } from '@/utils/currency'
import { Currency } from '@/components/Currency'
import { PointsModel } from '@/api/partners/points/PointsModel'
import { ResponseType } from '@/models/ResponseType'
import { CardsForExchange } from '../../models'
import { CardSelect } from '../../containers/ExchangePoints/CardSelect'
import { FormValues } from './models'

import {
  AmountHint,
  CurrentBalancePointsPlate,
  EnrollmentHint,
  Footer,
  FormContainer,
} from './styled'

export type ExchangePointsFormProps = Readonly<{
  form: UseFormReturn<FormValues, any>
  pointsData: PointsModel.ResponseData
  cards: CardsForExchange
  selectedCardEnrolment: string
  handleValidSubmit: SubmitHandler<FormValues>
}>

export const ExchangePointsForm = observer<ExchangePointsFormProps>((props) => {
  const { form, pointsData, cards, selectedCardEnrolment, handleValidSubmit } = props

  const {
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useFormContext<FormValues>()

  const amount = watch('amount')

  const isAmountValid = !errors.amount?.message && amount

  const isResolvePointsWithData = pointsData.type === ResponseType.ResolveWithData

  return (
    <FormContainer as="form" onSubmit={form.handleSubmit(handleValidSubmit)}>
      {isResolvePointsWithData && (
        <CurrentBalancePointsPlate
          currentBalance={pointsData.data.currentBalance || 0}
          bgColorTheme="dark"
          isCurrentBalanceOnly
        />
      )}
      <CardSelect cards={cards} />
      <Controller
        name="amount"
        control={form.control}
        render={({ field, fieldState }) => (
          <FieldControl isBlock error={fieldState.error?.message}>
            <InputMask
              placeholder="Сумма"
              type="text"
              isBlock
              suffix={` ${currency('RUB')}`}
              thousandSeparator=" "
              onClear={() => {
                setValue('amount', '')
                clearErrors()
              }}
              status={fieldState.error?.message ? 'error' : 'default'}
              rightSlot={
                !fieldState.error?.message &&
                field.value && <SuccessIcon color="icons/colors/success" />
              }
              autoComplete="off"
              isDisabled={!cards.length}
              {...field}
            />
            {isResolvePointsWithData && !fieldState.error?.message && !field.value && (
              <AmountHint isOpen>
                Можете вернуть от{' '}
                <Currency amount={pointsData.data.polzaInfo.minSumExchange} type="RUB" /> до{' '}
                <Currency amount={pointsData.data.currentBalance} type="RUB" />
              </AmountHint>
            )}
          </FieldControl>
        )}
      />
      <Footer>
        {isAmountValid && (
          <EnrollmentHint isOpen>Срок зачисления - {selectedCardEnrolment}</EnrollmentHint>
        )}
        <Button isFullWidth isDisabled={!isAmountValid || !cards.length} type="submit">
          {isAmountValid ? `Обменять на ${amount}` : 'Обменять'}
        </Button>
      </Footer>
    </FormContainer>
  )
})
