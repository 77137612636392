import styled from '@emotion/styled'
import { Button } from '@platform-ui/components'
import CategoriesLg from './assets/categories-lg.svg'
import CategoriesSm from './assets/categories-sm.svg'

export const Root = styled.div(({ theme }) => ({
  background: `url(${CategoriesSm}) no-repeat right, ${theme.color['surface/tertiary']}`,
  borderRadius: theme.borderRadius.r10,
  padding: theme.spacing.s8,
  marginTop: theme.spacing.s12,
  width: '100%',

  [`${theme.media.md}`]: {
    padding: theme.spacing.s10,
    background: `url(${CategoriesLg}) no-repeat right, ${theme.color['surface/tertiary']}`,
    marginTop: theme.spacing.s16,
  },
}))

export const Container = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}))

export const PrimaryText = styled.p(({ theme }) => ({
  ...theme.typography['headline/16'],
  marginBottom: theme.spacing.s2,
  maxWidth: 200,

  [`${theme.media.sm}`]: {
    marginBottom: theme.spacing.s2,
    maxWidth: 368,
  },

  [`${theme.media.md}`]: {
    ...theme.typography['headline/20'],
    marginBottom: theme.spacing.s2,
    maxWidth: 368,
  },
}))

export const SecondaryText = styled.p(({ theme }) => ({
  ...theme.typography['body/14'],
  color: theme.color['text/mainTransparent'],
  marginTop: theme.spacing.s2,

  [`${theme.media.md}`]: {
    ...theme.typography['body/16'],
  },
}))

export const SelectCategoriesButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  ...theme.typography['additional/14 button'],
  background: theme.color['surface/main'],
  maxWidth: 184,
  marginTop: theme.spacing.s8,

  ':after': {
    content: '""',
    position: 'absolute',
    top: -4,
    right: -4,
    width: 8,
    height: 8,
    borderRadius: theme.borderRadius.r4,
    border: `2px solid ${theme.color['surface/tertiary']}`,
    boxSizing: 'content-box',
    backgroundColor: theme.color['surface/error'],
  },
}))
