import { Dialog } from '@platform-ui/components'
import { AppContext } from '@/models/AppContext'
import { useAppContext } from '@/core'
import { ErrorBoundary } from '@/containers/ErrorBoundary'
import { DynamicLoading } from '@/containers/DynamicLoading'
import dynamic from '@/next/dynamic'
import { useRef, useState, useEffect, useCallback } from 'react'
import { isError } from 'react-query'

const ConnectCategoriesResultComponent = dynamic(
  () => import('../../components/ConnectCategoriesResult'),
  {
    loading: (props) => {
      return <DynamicLoading {...props} />
    },
  }
)

interface ConnectCategoriesResultProps {
  header: string
  text?: string
  isError?: boolean
  isLoading?: boolean
  showRetryButton?: boolean
  onClose?: () => void
  onRetryClick?: () => void
}

export const ConnectCategoriesResult = () => {
  const { config } = useAppContext<AppContext>()
  const { eventBus } = useAppContext<AppContext>()

  const dynamicActive = useRef(false)

  const [isOpen, setIsOpen] = useState(false)

  const [modalProps, setModalProps] = useState<ConnectCategoriesResultProps>({
    header: '',
    text: '',
    isError: false,
    isLoading: false,
    showRetryButton: false,
  })

  useEffect(() => {
    const openUnsubscribe = eventBus.connectCategoriesResult.open.on((props) => {
      setIsOpen(true)
      setModalProps({ isLoading: true, header: props.header })
    })

    const successUnsubscribe = eventBus.connectCategoriesResult.success.on((props) => {
      setModalProps({ isLoading: false, isError: false, header: props.header })
    })

    const errorUnsubscribe = eventBus.connectCategoriesResult.error.on((props) => {
      setModalProps({
        isLoading: false,
        isError: true,
        header: props.header,
        text: props.text,
        showRetryButton: props.showRetryButton,
        onClose: props.onClose,
        onRetryClick: props.onRetryClick,
      })
    })

    const closeUnsubscribe = eventBus.connectCategoriesResult.close.on(() => {
      setIsOpen(false)
    })

    return () => {
      openUnsubscribe()
      successUnsubscribe()
      errorUnsubscribe()
      closeUnsubscribe()
    }
  }, [eventBus])

  const handleClose = useCallback(() => {
    eventBus.connectCategoriesResult.close.emit()
  }, [eventBus])

  /**
   * Препятствует загрузке компонента до его востребования
   */
  if (isOpen) {
    dynamicActive.current = true
  }

  return (
    <ErrorBoundary onCatchError={handleClose}>
      {dynamicActive.current && (
        <Dialog
          isOpen={isOpen}
          backgroundColor={isError ? 'surface/tooltipAndArrested' : 'products/ingame'}
          rounded="r8"
          onOverlayClick={modalProps.onClose}
          onClose={modalProps.onClose}
          {...(!config.device.isMobile && {
            animationOverlayComponent: () => <></>,
          })}
        >
          <ConnectCategoriesResultComponent
            header={modalProps.header}
            text={modalProps.text}
            isError={modalProps.isError}
            isLoading={modalProps.isLoading}
            showRetryButton={modalProps.showRetryButton}
            onRetryClick={modalProps.onRetryClick}
            onClose={modalProps.onClose}
          />
        </Dialog>
      )}
    </ErrorBoundary>
  )
}
