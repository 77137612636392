import React, { forwardRef, useMemo } from 'react'
import {
  Root,
  PrimaryTextBlock,
  PrimaryText,
  SecondaryText,
  ChevronIcon,
  CategoryIconsBlock,
  CategoryIcon,
  AddCategoryIcon,
  Percent,
  IconWrapper,
} from './styled'
import { pluralize } from '@/utils'
import { FreeCategoriesModel } from '@/api/partners/freeCategories/FreeCategoriesModel'
import { PlusIcon } from '@platform-ui/icons'
import { CATEGORIES_TITLES } from '../../constants'

export type CategoriesForMonthPlateProps = Readonly<{
  className?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  month: string
  maxSelectedPositions: number
  selectedPositions: number
  categories: FreeCategoriesModel.CategoriesToSelect[]
}>

export const CategoriesForMonthPlate = forwardRef<HTMLButtonElement, CategoriesForMonthPlateProps>(
  (props, ref) => {
    const {
      month = 'месяц',
      maxSelectedPositions,
      selectedPositions,
      categories = [],
      ...otherProps
    } = props

    const filteredCategories = useMemo(
      () => categories.filter((category) => category.isSelected),
      [categories]
    )

    const categoriesText = useMemo((): string => {
      const allCategories = filteredCategories.map(
        (category) => `${category.cashbackPercent}% ${category.name.toLowerCase()}`
      )

      return allCategories.join(', ')
    }, [filteredCategories])

    const secondaryText = !!selectedPositions
      ? categoriesText
      : `Выберите ${maxSelectedPositions} ${pluralize(
          maxSelectedPositions,
          CATEGORIES_TITLES
        )} повышенного кэшбэка`

    const hasAddCategoryIcon = maxSelectedPositions > selectedPositions

    return (
      <Root ref={ref} {...otherProps}>
        <PrimaryTextBlock>
          <div>
            <PrimaryText>Категории на {month}</PrimaryText>
            <SecondaryText>{secondaryText}</SecondaryText>
          </div>
          <ChevronIcon color={'icons/main'} />
        </PrimaryTextBlock>
        {filteredCategories.length > 0 && (
          <CategoryIconsBlock>
            {filteredCategories.map((category) => (
              <IconWrapper key={category.id}>
                <CategoryIcon src={category.iconUrl || ''} />
                <Percent>{`${category.cashbackPercent}%`}</Percent>
              </IconWrapper>
            ))}
            {hasAddCategoryIcon && (
              <AddCategoryIcon>
                <PlusIcon color={'icons/primary'} />
              </AddCategoryIcon>
            )}
          </CategoryIconsBlock>
        )}
      </Root>
    )
  }
)
