import styled from '@emotion/styled'
import { Shimmer } from '@platform-ui/components'

export const Root = styled.div(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: theme.borderRadius.r6,
    padding: theme.spacing.s8,
    background: theme.color['surface/tertiary'],
    width: '100%',

    [`${theme.media.md}`]: {
      borderRadius: theme.borderRadius.r10,
      padding: theme.spacing.s12,
    },
  }
})

export const TitleShimmer = styled(Shimmer)(({ theme }) => ({
  height: 16,
  width: '180px',
  borderRadius: theme.borderRadius.r12,
  marginBottom: theme.spacing.s6,

  [`${theme.media.md}`]: {
    marginBottom: theme.spacing.s6,
    height: 32,
  },
}))

export const MainShimmer = styled(Shimmer)(({ theme }) => ({
  height: 10,
  width: '100%',
  borderRadius: theme.borderRadius.r8,

  [`${theme.media.md}`]: {
    height: 24,
  },
}))
