import styled from '@emotion/styled'

export const Root = styled.div(({ theme }) => ({
  display: 'block',
  borderRadius: theme.borderRadius.r10,
  padding: theme.spacing.s8,
  textDecoration: 'none',
  color: theme.color['text/main'],
  backgroundColor: theme.color['surface/tertiary'],
  textAlign: 'center',

  [`${theme.media.md}`]: {
    padding: '20px',
  },
}))

export const TextPrimary = styled.p(({ theme }) => ({
  ...theme.typography['body/16'],
  color: 'inherit',
  marginBottom: '0',
}))
