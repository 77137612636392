import styled from '@emotion/styled'
import { Button } from '@platform-ui/components'
import { Body14, Headline20 } from '@platform-ui/typography'

export const Root = styled.div(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: theme.color['text/main'],
}))

export const Content = styled.div(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const ImageContainer = styled.div(({ theme }) => ({
  marginBottom: theme.spacing.s16,
}))

export const ErrorTitle = styled(Headline20)(({ theme }) => ({
  marginBottom: theme.spacing.s6,
}))

export const ErrorText = styled(Body14)(({ theme }) => ({
  color: theme.color['text/secondary'],
}))

export const ButtonStyled = styled(Button)(() => ({
  width: '100%',
}))
