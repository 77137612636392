import styled from '@emotion/styled'
import { Button } from '@platform-ui/components'

export const ButtonWrapper = styled.div(() => ({
  position: 'fixed',
  padding: 32,
  width: '100%',
  left: 0,
  bottom: 0,
  background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 23.19%)',
  borderBottomLeftRadius: '24px',
  borderBottomRightRadius: '24px',
}))

export const ConnectButton = styled(Button)(({ theme }) => ({
  ':disabled': {
    backgroundColor: theme.color['surface/tertiary'],
  },
}))
