import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { CardsForExchange, ModalContent } from '../../models'
import { ErrorResultContent } from './ErrorResultContent/ErrorResultContent'
import { SuccessResultContent } from './SuccessResultContent'

interface ExchangePointsResultProps {
  amount: number
  oldPointsSum: number
  newPointsSum: number
  cardEnrollment: string
  selectedMaskCardNumberForExchange: string
  status: string
  cards: CardsForExchange
  setContent: React.Dispatch<React.SetStateAction<ModalContent>>
  resetContent: () => void
  resetMutation: () => void
  setIsBackButtonShown: React.Dispatch<React.SetStateAction<boolean>>
}

export const ExchangePointsResult = observer<ExchangePointsResultProps>((props) => {
  const {
    amount,
    oldPointsSum,
    newPointsSum,
    cardEnrollment,
    selectedMaskCardNumberForExchange,
    status,
    cards,
    setContent,
    resetContent,
    resetMutation,
    setIsBackButtonShown,
  } = props

  useEffect(() => {
    setIsBackButtonShown(false)

    return () => {
      setIsBackButtonShown(true)
    }
  }, [])

  if (status === 'error') {
    return <ErrorResultContent onRetryButtonClick={resetMutation} />
  }

  return (
    <SuccessResultContent
      amount={amount}
      oldPointsSum={oldPointsSum}
      newPointsSum={newPointsSum}
      cardEnrollment={cardEnrollment}
      selectedMaskCardNumberForExchange={selectedMaskCardNumberForExchange}
      cards={cards}
      setContent={setContent}
      resetContent={resetContent}
    />
  )
})
