import { useQuery, UseQueryOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { FreeCategoriesModel } from './FreeCategoriesModel'
import { adapter } from './adapters/adapter'

export const freeCategories: FreeCategoriesModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.partners.freeCategories,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.get<
    FreeCategoriesModel.ResponseData,
    FreeCategoriesModel.Props,
    FreeCategoriesModel.RequestHeaders,
    FreeCategoriesModel.ErrorResponse
  >({
    url: path,
    headers: null,
    query: {
      maskCardNumber: props.maskCardNumber,
      contractNumber: props.contractNumber,
    },
    responseAdapter: adapter,
    control,
  })

  return response
}

function freeCategoriesKey(props: FreeCategoriesModel.Props) {
  return ['/api/partners/freeCategories', ...Object.values(props)]
}

export function useFreeCategoriesQuery<
  T extends FreeCategoriesModel.ResponseData = FreeCategoriesModel.ResponseData
>(
  props: FreeCategoriesModel.Props,
  options: Omit<
    UseQueryOptions<FreeCategoriesModel.ResponseData, FreeCategoriesModel.ErrorResponse, T>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const key = freeCategoriesKey(props)
  const result = useQuery(key, () => freeCategories(props).then((res) => res.response), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    ...options,
  })

  return result
}
