import { Header, HeaderWrapper, SubHeader } from './styled'

interface SelectCategoriesHeaderProps {
  header: string
  subHeader?: string
}

export const SelectCategoriesHeader: React.FC<SelectCategoriesHeaderProps> = (props) => {
  const { header, subHeader } = props

  return (
    <HeaderWrapper>
      <Header>{header}</Header>
      {!!subHeader && <SubHeader>{subHeader}</SubHeader>}
    </HeaderWrapper>
  )
}
