import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { BaseModal } from '@platform-ui/components'
import { ExchangePoints } from '../ExchangePoints'
import { CashbackAndBonuses } from '../CashbackAndBonuses'
import { SelectCashbackCategories } from '../SelectCashbackСategories'
import { MODAL_TITLES } from '../../constants'
import { ModalContent, PartnersContentType } from '../../models'
import { ConnectCategoriesResult } from '../ConnectCategoriesResult'

export interface PartnersProps {
  onClose: () => void
  isOpen: boolean
  maskCardNumber: string
}

export const Partners = observer<PartnersProps>((props) => {
  const { isOpen, onClose, maskCardNumber } = props
  const [isSuccess, setIsSuccess] = useState(false)
  const [content, setContent] = useState<ModalContent>({
    type: PartnersContentType.CashbackAndBonuses,
    title: MODAL_TITLES.cashbackAndBonuses,
    subtitle: null,
    month: null,
  })

  const [isBackButtonShown, setIsBackButtonShown] = useState<boolean>(true)

  const hasBackBtn = content.type !== PartnersContentType.CashbackAndBonuses && isBackButtonShown

  const resetContent = () => {
    setContent({
      type: PartnersContentType.CashbackAndBonuses,
      title: MODAL_TITLES.cashbackAndBonuses,
      subtitle: null,
      month: null,
    })
  }

  useEffect(() => {
    if (isSuccess && !isOpen) {
      setIsSuccess(false)
    }
  }, [isOpen, isSuccess])

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      backgroundColor={'surface/main'}
      onExited={resetContent}
      isBackBtn={hasBackBtn}
      backBtnClick={resetContent}
      title={content.title || MODAL_TITLES.cashbackAndBonuses}
      subtitle={content.subtitle}
    >
      {content.type === PartnersContentType.CashbackAndBonuses && (
        <CashbackAndBonuses changeContent={setContent} maskCardNumber={maskCardNumber} />
      )}
      {content.type === PartnersContentType.ExchangePoints && (
        <ExchangePoints
          maskCardNumber={maskCardNumber}
          setContent={setContent}
          resetContent={resetContent}
          setIsBackButtonShown={setIsBackButtonShown}
        />
      )}
      {content.type === PartnersContentType.SelectCashbackCategories && (
        <SelectCashbackCategories
          maskCardNumber={maskCardNumber}
          month={content.month}
          resetContent={resetContent}
        />
      )}

      <ConnectCategoriesResult />
    </BaseModal>
  )
})
