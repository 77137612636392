import { MutateOptions, useMutation } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { SetFreeCategoriesModel } from './SetFreeCategoriesModel'
import { adapter } from './adapters/adapter'

export const setFreeCategories: SetFreeCategoriesModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.partners.setFreeCategories,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    SetFreeCategoriesModel.ResponseData,
    void,
    SetFreeCategoriesModel.Props,
    SetFreeCategoriesModel.RequestHeaders,
    SetFreeCategoriesModel.ErrorResponse
  >({
    url: path,
    data: {
      maskCardNumber: props.maskCardNumber,
      monthNumber: props.monthNumber,
      categoryId: props.categoryId,
      contractNumber: props.contractNumber,
    },
    headers: null,
    responseAdapter: adapter,
    control,
  })

  return response
}

export function useSetFreeCategories(
  options?: MutateOptions<
    SetFreeCategoriesModel.Response,
    SetFreeCategoriesModel.ErrorResponse,
    SetFreeCategoriesModel.Props
  >
) {
  return useMutation(setFreeCategories, options)
}
