import { SelectProductsModel } from '@/components/SelectProducts'
import { CardsForExchange } from '../models'
import { ProductsModel } from '@/api/products/products'

export const createSelectCardsItems = (cards: CardsForExchange): SelectProductsModel.Item[] => {
  return [
    {
      objectType: SelectProductsModel.ObjectType.Category,
      title: 'Карты',
      items: cards.map((item) => {
        return {
          objectType: SelectProductsModel.ObjectType.Card,
          amount: item.availableBalance,
          bgColor: item.bgColor,
          lastFourCardDigits: item.lastFourCardDigits,
          currencyType: item.currencyType,
          description: item.description,
          id: item.maskCardNumber,
          bgColorTheme: item.bgColorTheme,
          paymentSystem: item.paymentSystem,
          title: item.productName,
        }
      }),
    },
  ]
}
