import React, { forwardRef } from 'react'
import { CoinsIcon } from '@platform-ui/icons/CoinsIcon'
import { pluralize } from '@/utils'
import { formatDate } from '@/utils/formatDate'
import { currency } from '@/utils/currency'
import {
  Root,
  CurrentBalanceBlock,
  PointsToBeBlock,
  ExchangeConditionBlock,
  UpdateNextMonth,
  Dot,
  MakePurchases,
  PointsToBurnIcon,
  ChevronIcon,
} from './styled'

export type PointsPlateProps = Readonly<{
  className?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  currentBalance: number
  minPointsSumExchange?: number
  pointsToBurn?: number
  pointsToBe?: number
  isExchangePossible?: boolean
  bgColorTheme: 'dark' | 'light'
  isCurrentBalanceOnly?: boolean
}>

const getDateWithNextMonth = (): Date => {
  const changedDate = new Date()
  changedDate.setMonth(changedDate.getMonth() + 1)
  changedDate.setDate(1)

  return changedDate
}

export const TITLES = ['балл', 'балла', 'баллов']
export const MIN_POINTS_SUM_EXCHANGE_TITLES = ['балла', 'баллов', 'баллов']

export const PointsPlate = forwardRef<HTMLButtonElement, PointsPlateProps>((props, ref) => {
  const {
    className,
    currentBalance,
    minPointsSumExchange = 1,
    pointsToBurn = 0,
    pointsToBe = 0,
    isExchangePossible,
    bgColorTheme,
    isCurrentBalanceOnly,
    ...otherProps
  } = props

  const formattedCurrentBalance = `${currency(currentBalance, null).format()} ${pluralize(
    currentBalance,
    TITLES
  )}`

  const formattedMinPointsSumExchange = `${currency(
    minPointsSumExchange,
    null
  ).format()} ${pluralize(minPointsSumExchange, MIN_POINTS_SUM_EXCHANGE_TITLES)}`

  const formattedPointsToBe = `${currency(pointsToBe, null).format()} ${pluralize(
    pointsToBe,
    TITLES
  )}`

  const isShowMakePurchases = !pointsToBe && !currentBalance
  const isShowPointsToBe = currentBalance > 0 && pointsToBe > 0

  const nextMonthDate = getDateWithNextMonth()
  const formattedUpdateNextMonth = formatDate(nextMonthDate, '(начислим D MMMM)')

  const bgColor =
    bgColorTheme === 'dark'
      ? 'linear-gradient(133.61deg, #5D1766 0%, #301434 100%)'
      : 'surface/tertiary'

  const textColor = bgColorTheme === 'dark' ? 'text/tertiary' : 'text/main'

  if (isCurrentBalanceOnly) {
    return (
      <Root ref={ref} className={className} bgColor={bgColor} textColor={textColor} {...otherProps}>
        <CurrentBalanceBlock>
          <div>{formattedCurrentBalance}</div>
        </CurrentBalanceBlock>
        <ExchangeConditionBlock
          textColor={bgColorTheme === 'dark' ? 'text/tertiaryTransparent' : 'text/secondary'}
        >
          1 балл = 1 рубль
        </ExchangeConditionBlock>
      </Root>
    )
  }

  return (
    <Root
      ref={ref}
      className={className}
      bgColor={bgColor}
      textColor={textColor}
      isExchangePossible={isExchangePossible}
      {...otherProps}
    >
      <CurrentBalanceBlock>
        <div>
          {!!pointsToBurn && <PointsToBurnIcon color={textColor} />}
          {formattedCurrentBalance}
        </div>
        {isExchangePossible && <ChevronIcon />}
      </CurrentBalanceBlock>
      <PointsToBeBlock>
        {isShowMakePurchases && (
          <>
            <CoinsIcon color="icons/main" size="s10" />
            <MakePurchases>Совершайте покупки и копите баллы</MakePurchases>
          </>
        )}
        {isShowPointsToBe && (
          <>
            {formattedPointsToBe}
            <UpdateNextMonth
              textColor={
                bgColorTheme === 'dark' ? 'text/tertiaryTransparent' : 'text/mainTransparent'
              }
            >
              {formattedUpdateNextMonth}
            </UpdateNextMonth>
          </>
        )}
      </PointsToBeBlock>
      <ExchangeConditionBlock
        textColor={bgColorTheme === 'dark' ? 'text/tertiaryTransparent' : 'text/secondary'}
      >
        1 балл = 1 рубль
        <Dot />
        {`Обмен от ${formattedMinPointsSumExchange}`}
      </ExchangeConditionBlock>
    </Root>
  )
})
