import styled from '@emotion/styled'
import { Shimmer } from '@platform-ui/components'

export const Root = styled.div(({ theme }) => ({
  display: 'block',
  borderRadius: theme.borderRadius.r10,
  padding: theme.spacing.s8,
  background: theme.color['surface/tertiary'],
  marginTop: theme.spacing.s12,

  [`${theme.media.md}`]: {
    padding: theme.spacing.s10,
    marginTop: theme.spacing.s16,
  },
}))

export const TitleShimmer = styled(Shimmer)(({ theme }) => ({
  maxWidth: 180,
  height: 16,
  marginBottom: 10,
  borderRadius: theme.borderRadius.r4,
  background: theme.color['surface/shimmer'],

  [`${theme.media.md}`]: {
    maxWidth: 260,
    height: 24,
    borderRadius: theme.borderRadius.r6,
    marginBottom: theme.spacing.s6,
  },
}))

export const DescriptionShimmer = styled(Shimmer)(({ theme }) => ({
  maxWidth: 290,
  height: 12,
  borderRadius: 6,
  background: theme.color['surface/shimmer'],

  [`${theme.media.md}`]: {
    maxWidth: 425,
    height: 16,
    borderRadius: theme.borderRadius.r4,
  },
}))

export const CategoriesShimmerWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing.s6,
  marginTop: theme.spacing.s8,
}))

export const CategoryIconShimmer = styled(Shimmer)(({ theme }) => ({
  borderRadius: theme.borderRadius.r10,
  background: theme.color['surface/shimmer'],
  width: 40,
  height: 40,

  [`${theme.media.md}`]: {
    width: 48,
    height: 48,
    borderRadius: theme.borderRadius.r12,
  },
}))
