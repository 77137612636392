import { ButtonWrapper, ConnectButton } from './styled'

interface ConnectCategoriesButtonProps {
  isDisabled: boolean
  onClick: () => void
}

export const ConnectCategoriesButton: React.FC<ConnectCategoriesButtonProps> = (props) => {
  const { isDisabled, onClick } = props

  return (
    <ButtonWrapper>
      <ConnectButton isFullWidth isDisabled={isDisabled} onClick={onClick}>
        Подключить
      </ConnectButton>
    </ButtonWrapper>
  )
}
