import { SelectProductsModel } from '@/components/SelectProducts'
import { createSelector } from 'reselect'
import { createSelectCardsItems } from '../utils'
import { CardsForExchange } from '../models'

export const selectCardsItemsSelector = createSelector(
  (state: { cards: CardsForExchange }) => state.cards,
  (cards): SelectProductsModel.Item[] => {
    return createSelectCardsItems(cards)
  }
)
