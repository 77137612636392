import {
  AmountInputShimmer,
  CurrentBalancePointsPlateShimmer,
  FormContainer,
  Root,
  SelectProductsContainer,
  SelectProductsShimmer,
  SelectTitleShimmer,
  SubmitButtonShimmer,
} from './styled'

export const ExchangePointsFormShimmer: React.FC = () => (
  <Root>
    <FormContainer>
      <CurrentBalancePointsPlateShimmer />
      <SelectProductsContainer>
        <SelectTitleShimmer />
        <SelectProductsShimmer />
      </SelectProductsContainer>
      <AmountInputShimmer />
    </FormContainer>
    <SubmitButtonShimmer />
  </Root>
)
