import styled from '@emotion/styled'
import { Shimmer } from '@platform-ui/components'
import { PointsPlate } from '@/features/partners-modal/components/PointsPlate'
import { Accordion } from '@platform-ui/components/Accordion'

export const CardSelectWrapper = styled.div(({ theme }) => ({
  paddingLeft: theme.spacing.s6,
}))

export const AmountHint = styled(Accordion)(({ theme }) => ({
  ...theme.typography['body/14'],
  color: theme.color['text/secondary'],
  paddingLeft: theme.spacing.s8,
  marginTop: theme.spacing.s4,
}))

export const CurrentBalancePointsPlate = styled(PointsPlate)(() => ({
  cursor: 'default',
}))

export const CurrentBalancePointsPlateShimmer = styled(Shimmer)(({ theme }) => ({
  height: 116,
  width: '100%',
  borderRadius: theme.borderRadius.r6,
}))

export const SelectTitleShimmer = styled(Shimmer)(({ theme }) => ({
  height: 24,
  width: 70,
  borderRadius: theme.borderRadius.r6,
}))

export const SelectProductsContainer = styled.div(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'start',
  gap: theme.spacing.s8,
}))

export const SelectProductsShimmer = styled(Shimmer)(({ theme }) => ({
  height: 92,
  width: '100%',
  borderRadius: theme.borderRadius.r6,
}))

export const AmountInputShimmer = styled(Shimmer)(({ theme }) => ({
  height: 64,
  width: '100%',
  borderRadius: theme.borderRadius.r6,
}))

export const SubmitButtonShimmer = styled(Shimmer)(({ theme }) => ({
  height: 56,
  width: '100%',
  borderRadius: theme.borderRadius.r6,
}))

export const Root = styled.div(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: 110,
}))

export const FormContainer = styled.div(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  gap: theme.spacing.s16,
}))

export const EnrollmentHint = styled(Accordion)(({ theme }) => ({
  ...theme.typography['body/14'],
  color: theme.color['text/main'],
  textAlign: 'center',
}))

export const Footer = styled.div(({ theme }) => ({
  position: 'fixed',
  padding: 32,
  left: 0,
  bottom: 0,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: theme.spacing.s8,
}))
