import styled from '@emotion/styled'

export const HeaderWrapper = styled.div(({ theme }) => ({
  marginBottom: theme.spacing.s8,

  [`${theme.media.md}`]: {
    marginBottom: theme.spacing.s4,
  },
}))

export const Header = styled.div(({ theme }) => ({
  ...theme.typography['headline/28'],
  marginBottom: theme.spacing.s6,
}))

export const SubHeader = styled.div(({ theme }) => ({
  ...theme.typography['body/16'],
}))
