import styled from '@emotion/styled'

export const CategoriesList = styled.ul(() => ({
  listStyle: 'none',
  padding: 0,
  margin: 0,
}))

export const LinksWrapper = styled.div(({ theme }) => ({
  marginTop: theme.spacing.s16,
}))
