import { AdapterModel } from './AdapterModel'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'

export const adapter: AdapterModel.Func = (data) => {
  const errorData = data as AdapterModel.ResponseDataError

  /**
   * Сервер всегда возвращает status=200 в заголовке
   * Реальный статус приходит в теле ответа
   * 401 или 500+ считаем rejected ответами с наличием критической ошибки
   */
  if (errorData?.StatusCode === 401 || errorData?.StatusCode >= 500) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: errorData.ErrorDetails
        ? errorData.ErrorDetails.map(({ Error }) => ({
            type: Error,
            message: Error,
          }))
        : [],
    }
    throw HttpClient.createError(errorData?.StatusCode, exception)
  }

  try {
    const successData = data as AdapterModel.ResponseDataSuccess

    return {
      type: ResponseType.ResolveWithData,
      data: {
        currentBalance: successData.Result.CurrentBalance,
        isExchangePossible: successData.Result.IsExchangePossible,
        levelInfo: {
          currentLevel: successData.Result.LevelInfo?.CurrentLevel,
        },
        monthBalance: successData.Result.MonthBalance?.map((monthBalance) => ({
          maskCardNumber: monthBalance.MaskCardNumber,
          balance: monthBalance.Balance,
        })),
        pointsToBe: successData.Result.PointsToBe,
        pointsToBurn: successData.Result.PointsToBurn,
        polzaInfo: {
          minSumExchange: successData.Result.PolzaInfo?.MinSumExchange,
          expiration: successData.Result.PolzaInfo?.Expiration,
        },
        polzaPartnersCatalogImageUrl: successData.Result.PolzaPartnersCatalogImageUrl,
      },
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception, error)
  }
}
