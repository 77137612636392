import React, { FC } from 'react'
import { FreeCategoriesModel } from '@/api/partners/freeCategories/FreeCategoriesModel'
import { formatDate } from '@/utils/formatDate'
import { FreeCategoriesPlate } from '../../components/FreeCategoriesPlate'
import { CategoriesForMonthPlate } from '../../components/CategoriesForMonthPlate'
import { Delimiter, Root } from './styled'
import { ModalContent, PartnersContentType } from '@/features/partners-modal/models'
import { MODAL_TITLES } from '@/features/partners-modal/constants'

export type FreeCategoriesContainerProps = Readonly<{
  changeContent?: React.Dispatch<React.SetStateAction<ModalContent>>
  freeCategories: FreeCategoriesModel.Data
}>

export const FreeCategoriesContainer: FC<FreeCategoriesContainerProps> = (props) => {
  const { freeCategories, changeContent } = props

  const sortedFreeCategories = freeCategories.sort((a, b) => {
    if (a.monthNumber < b.monthNumber) {
      return -1
    }
    return 0
  })

  const currentMonthCategories = sortedFreeCategories[0]

  const { monthNumber, maxSelectedPositions, cashbackPercentText, selectedPositions } =
    currentMonthCategories

  const currentMonth = formatDate(new Date(null, monthNumber - 1), 'MMMM')

  const hasFreeCategoriesPlate = !sortedFreeCategories.some(
    (freeCategory) => freeCategory.selectedPositions !== 0
  )

  const clickHandler = (month: number, monthName: string, canSelectCategories: boolean) => {
    changeContent({
      type: PartnersContentType.SelectCashbackCategories,
      title: canSelectCategories
        ? `${MODAL_TITLES.selectCashbackCategories} ${monthName}`
        : MODAL_TITLES.cashbackCategories,
      month,
      subtitle: null,
    })
  }

  if (hasFreeCategoriesPlate) {
    return (
      <FreeCategoriesPlate
        month={currentMonth}
        maxSelectedPositions={maxSelectedPositions}
        cashbackPercentText={cashbackPercentText}
        onClick={() => {
          clickHandler(monthNumber, currentMonth, maxSelectedPositions !== selectedPositions)
        }}
      />
    )
  }

  const nextMonthCategories = sortedFreeCategories[1]

  const nextMonth = formatDate(new Date(null, nextMonthCategories?.monthNumber - 1), 'MMMM')

  return (
    <Root>
      {nextMonthCategories && (
        <>
          <CategoriesForMonthPlate
            month={nextMonth}
            maxSelectedPositions={nextMonthCategories?.maxSelectedPositions}
            selectedPositions={nextMonthCategories?.selectedPositions}
            categories={nextMonthCategories?.categories}
            onClick={() => {
              clickHandler(
                nextMonthCategories.monthNumber,
                nextMonth,
                nextMonthCategories.maxSelectedPositions !== nextMonthCategories.selectedPositions
              )
            }}
          />
          <Delimiter />
        </>
      )}

      {currentMonthCategories && (
        <CategoriesForMonthPlate
          month={currentMonth}
          maxSelectedPositions={currentMonthCategories?.maxSelectedPositions}
          selectedPositions={currentMonthCategories?.selectedPositions}
          categories={currentMonthCategories?.categories}
          onClick={() => {
            clickHandler(
              currentMonthCategories.monthNumber,
              currentMonth,
              currentMonthCategories.maxSelectedPositions !==
                currentMonthCategories.selectedPositions
            )
          }}
        />
      )}
    </Root>
  )
}
