import { MutateOptions, useMutation } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { adapter } from './adapters/adapter'
import { PolzaLinkModel } from './PolzaLinkModel'

export const points: PolzaLinkModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.partners.polzaLink,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    PolzaLinkModel.ResponseData,
    void,
    PolzaLinkModel.RequestData,
    PolzaLinkModel.RequestHeaders,
    PolzaLinkModel.ErrorResponse
  >({
    url: path,
    data: null,
    headers: {
      _ver_: props.apiVersion,
      _os_: '4',
    },
    responseAdapter: adapter,
    control,
  })

  return response
}

export function useGetPolzaLink(
  options?: MutateOptions<
    PolzaLinkModel.Response,
    PolzaLinkModel.ErrorResponse,
    PolzaLinkModel.Props
  >
) {
  return useMutation(points, options)
}
