import styled from '@emotion/styled'
import { InfoIcon } from '@platform-ui/icons'
import DefaultCategoryIcon from '@/features/partners/components/assets/default-category-icon.svg'

export const ItemWrapper = styled.li<{ disabled: boolean }>(({ theme, disabled }) => ({
  display: 'flex',
  gap: 12,
  alignItems: 'center',
  padding: `${theme.spacing.s6} 0`,
  opacity: disabled ? 0.5 : 1,

  [`${theme.media.md}`]: {
    padding: `${theme.spacing.s6} 0`,
  },
}))

export const Icon = styled.img(({ theme }) => ({
  width: '40px',
  height: '40px',
  minWidth: '40px',
  minHeight: '40px',
  borderRadius: theme.borderRadius.r10,
  position: 'relative',

  '::before': {
    content: '""',
    position: 'absolute',
    left: theme.spacing.s10,
    top: theme.spacing.s10,
    transform: 'translate(-50%, -50%)',
    width: '40px',
    height: '40px',
    minWidth: '40px',
    minHeight: '40px',
    borderRadius: theme.borderRadius.r10,
    background: `url('${DefaultCategoryIcon}') no-repeat, ${theme.color['icons/PFM/twentyeight']}`,
    backgroundPosition: 'center',
    boxSizing: 'content-box',
  },
}))

export const Name = styled.div(({ theme }) => ({
  ...theme.typography['headline/16'],
  width: '100%',
}))

export const Info = styled(InfoIcon)(({ theme }) => ({
  width: '22px',
  height: '22px',
  borderRadius: theme.borderRadius.r10,
  backgroundColor: theme.color['icons/secondary'],
  color: theme.color['text/tertiary'],
  cursor: 'pointer',
}))

export const TooltipBody = styled.div(({ theme }) => ({
  padding: `${theme.spacing.s4} ${theme.spacing.s6}`,
  maxWidth: '500px',
}))
