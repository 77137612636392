import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import styled from '@emotion/styled'
import { useFreeCategoriesQuery } from '@/api/partners/freeCategories'
import { ResponseType } from '@/models/ResponseType'
import { FreeCategoriesContainer } from './FreeCategoriesContainer'
import { FreeCategoriesShimmer } from '../../components/FreeCategoriesShimmer'
import { PartnersError } from '../../../partners/components/PartnersError'
import { ModalContent } from '@/features/partners-modal/models'

export type FreeCategoriesWidgetProps = Readonly<{
  maskCardNumber: string
  className?: string
  changeContent?: React.Dispatch<React.SetStateAction<ModalContent>>
}>

const PartnersErrorWrapper = styled(PartnersError)(({ theme }) => ({
  marginTop: theme.spacing.s12,

  [`${theme.media.md}`]: {
    marginTop: theme.spacing.s16,
  },
}))

export const FreeCategoriesWidget: FC<FreeCategoriesWidgetProps> = observer((props) => {
  const { maskCardNumber, changeContent } = props

  const { status, data, error, isRefetching, refetch } = useFreeCategoriesQuery({
    maskCardNumber,
  })

  if (status === 'loading' || isRefetching) {
    return <FreeCategoriesShimmer />
  }

  if (status === 'error') {
    return (
      <PartnersErrorWrapper
        message={error.response.systemErrors?.[0]?.message || 'Не удалось загрузить категории'}
        retryClick={() => refetch()}
      />
    )
  }

  if (status === 'success' && data.type === ResponseType.ResolveWithData) {
    const freeCategories = data.data

    return freeCategories?.length > 0 ? (
      <FreeCategoriesContainer freeCategories={freeCategories} changeContent={changeContent} />
    ) : (
      <PartnersErrorWrapper message="Не удалось загрузить категории" retryClick={() => refetch()} />
    )
  }

  return null
})
