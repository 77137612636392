import styled from '@emotion/styled'
import { ButtonBase } from '@platform-ui/components'
import { FireIcon } from '@platform-ui/icons/FireIcon'
import { ChevronRightIcon } from '@platform-ui/icons/ChevronRightIcon'
import { ITheme } from '@platform-ui/core'

export const Root = styled(ButtonBase)<{
  textColor: keyof ITheme['color'] | string
  bgColor: keyof ITheme['color'] | string
  isExchangePossible?: boolean
}>(({ theme, textColor, bgColor, isExchangePossible }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: theme.borderRadius.r6,
  padding: theme.spacing.s8,
  color: theme.color[textColor] || textColor,
  background: theme.color[bgColor] || bgColor,
  textAlign: 'left',
  width: '100%',
  cursor: isExchangePossible ? 'pointer' : 'default',

  [`${theme.media.md}`]: {
    borderRadius: theme.borderRadius.r10,
    padding: theme.spacing.s12,
  },
}))

export const PointsToBurnIcon = styled(FireIcon)(({ theme }) => ({
  display: 'inline-block',
  marginRight: theme.spacing.s4,
  width: theme.iconSize.s8,
  height: theme.iconSize.s8,

  [`${theme.media.md}`]: {
    width: theme.iconSize.s12,
    height: theme.iconSize.s12,
  },
}))

export const ChevronIcon = styled(ChevronRightIcon)(({ theme }) => ({
  width: theme.iconSize.s12,
  height: theme.iconSize.s12,

  [`${theme.media.md}`]: {
    width: theme.iconSize.s16,
    height: theme.iconSize.s16,
  },
}))

export const CurrentBalanceBlock = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  ...theme.typography['headline/28'],

  [`${theme.media.md}`]: {
    ...theme.typography['headline/32'],
  },
}))

export const PointsToBeBlock = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginTop: theme.spacing.s4,
  width: '100%',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '18px',
  color: 'inherit',

  [`${theme.media.md}`]: {
    ...theme.typography['body/16'],
    marginTop: theme.spacing.s4,
  },
}))

export const MakePurchases = styled.span(({ theme }) => ({
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '18px',
  letterSpacing: '-0.2px',
  color: theme.color['text/main'],
  textAlign: 'center',
  marginLeft: theme.spacing.s4,
  ...theme.typography['body/14'],

  [`${theme.media.md}`]: {
    ...theme.typography['body/16'],
  },
}))

export const UpdateNextMonth = styled.span<{
  textColor: keyof ITheme['color'] | string
}>(({ theme, textColor }) => {
  return {
    display: 'inline-block',
    marginLeft: '8px',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: '-0.2px',
    color: theme.color[textColor] || textColor,

    [`${theme.media.md}`]: {
      fontSize: 16,
      lineHeight: '24px',
    },
  }
})

export const Dot = styled.span(({ theme }) => ({
  ':before': {
    content: '""',
    display: 'inline-block',
    width: '4px',
    height: '4px',
    borderRadius: '50%',
    marginBottom: '3px',
    marginLeft: '8px',
    marginRight: '8px',
    background: theme.color['icons/secondary'],
  },

  [`${theme.media.md}`]: {
    ':before': {
      width: '6px',
      height: '6px',
    },
  },
}))

export const ExchangeConditionBlock = styled.div<{
  textColor: keyof ITheme['color'] | string
}>(({ theme, textColor }) => ({
  marginTop: 8,
  width: '100%',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '18px',
  color: theme.color[textColor] || textColor,

  [`${theme.media.md}`]: {
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '-0.2px',
  },
}))
