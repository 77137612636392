import styled from '@emotion/styled'
import { SelectProducts } from '@/components/SelectProducts'

export const SelectTitle = styled.p(({ theme }) => ({
  ...theme.typography['headline/16'],
  paddingLeft: theme.spacing.s6,
  color: 'inherit',
}))

export const SelectProductsContainer = styled.div(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'start',
  gap: theme.spacing.s8,
}))

export const SelectCardsForExchange = styled(SelectProducts)(({ theme }) => ({
  width: '100%',

  ['> button']: {
    minHeight: 92,

    [`${theme.media.md}`]: {
      minHeight: 80,
    },
  },
}))
