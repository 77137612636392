import styled from '@emotion/styled'
import { ButtonBase } from '@platform-ui/components'
import { ChevronRightIcon } from '@platform-ui/icons'
import DefaultCategoryIcon from '@/features/partners/components/assets/default-category-icon.svg'

export const Root = styled(ButtonBase)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: 0,
  width: '100%',
  textAlign: 'left',
}))

export const PrimaryTextBlock = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
}))

export const PrimaryText = styled.p(({ theme }) => ({
  ...theme.typography['headline/16'],
  marginBottom: theme.spacing.s2,

  [`${theme.media.md}`]: {
    ...theme.typography['headline/20'],
    marginBottom: theme.spacing.s2,
  },
}))

export const SecondaryText = styled.p(({ theme }) => ({
  ...theme.typography['body/14'],
  color: theme.color['text/mainTransparent'],
  marginTop: theme.spacing.s2,
  width: '100%',

  [`${theme.media.md}`]: {
    ...theme.typography['body/16'],
  },
}))

export const ChevronIcon = styled(ChevronRightIcon)(({ theme }) => ({
  width: theme.iconSize.s12,
  height: theme.iconSize.s12,

  [`${theme.media.md}`]: {
    width: theme.iconSize.s16,
    height: theme.iconSize.s16,
  },
}))

export const CategoryIconsBlock = styled.div(({ theme }) => ({
  marginTop: theme.spacing.s6,
  marginBottom: theme.spacing.s6,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing.s6,
  width: '100%',

  [`${theme.media.md}`]: {
    marginTop: theme.spacing.s8,
    marginBottom: theme.spacing.s8,
  },
}))

export const IconWrapper = styled.div(() => ({
  position: 'relative',
}))

export const CategoryIcon = styled.img(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: theme.borderRadius.r10,

  '::before': {
    content: '""',
    position: 'absolute',
    left: theme.spacing.s10,
    top: theme.spacing.s10,
    transform: 'translate(-50%, -50%)',
    width: 40,
    height: 40,
    borderRadius: theme.borderRadius.r10,
    background: `url('${DefaultCategoryIcon}') no-repeat, ${theme.color['icons/PFM/twentyeight']}`,
    backgroundPosition: 'center',
    boxSizing: 'content-box',
  },

  [`${theme.media.md}`]: {
    width: 48,
    height: 48,
    borderRadius: theme.borderRadius.r12,
    boxSizing: 'content-box',

    '::before': {
      content: '""',
      position: 'absolute',
      left: theme.spacing.s12,
      top: theme.spacing.s12,
      transform: 'translate(-50%, -50%)',
      width: 48,
      height: 48,
      borderRadius: theme.borderRadius.r12,
    },
  },
}))

export const AddCategoryIcon = styled.span(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 40,
  height: 40,
  borderRadius: theme.borderRadius.r10,
  border: `2px dashed ${theme.color['icons/primary']}`,

  [`${theme.media.md}`]: {
    width: 48,
    height: 48,
    borderRadius: theme.borderRadius.r12,
    border: `2.4px dashed ${theme.color['icons/primary']}`,
  },
}))

export const Percent = styled.div(({ theme }) => ({
  ...theme.typography['caption/10'],
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  left: '50%',
  transform: 'translateX(-50%)',
  bottom: -8,
  height: 16,
  paddingLeft: 4,
  paddingRight: 4,
  borderRadius: theme.borderRadius.r6,
  border: `2px solid ${theme.color['surface/tertiary']}`,
  backgroundColor: theme.color['surface/primary'],
  color: theme.color['text/tertiary'],

  [`${theme.media.md}`]: {
    ...theme.typography['headline/14'],
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: -14,
    height: 22,
    borderRadius: theme.borderRadius.r6,
    border: `2px solid ${theme.color['surface/tertiary']}`,
  },
}))
