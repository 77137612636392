import React from 'react'
import { observer } from 'mobx-react-lite'
import { useSettingsQuery } from '@/api/settings/settings'
import { ResponseType } from '@/models/ResponseType'
import { FreeCategoriesWidget } from '../FreeCategoriesWidget'
import { PointsWidget } from '../PointsWidget'
import { ModalContent, PartnersContentType } from '../../models'
import { MODAL_TITLES } from '../../constants'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { useGetPolzaLink } from '@/api/partners/polzaLink'
import { PolzaButton } from '../../components/PolzaButton'

const DEFAULT_POLZA_LINK = 'https://polza.home.bank'

export interface CashbackAndBonusesProps {
  maskCardNumber: string
  changeContent?: React.Dispatch<React.SetStateAction<ModalContent>>
}

export const CashbackAndBonuses = observer<CashbackAndBonusesProps>((props) => {
  const { maskCardNumber, changeContent } = props
  const context = useAppContext<AppContext>()

  const { data } = useSettingsQuery()

  const { mutateAsync: fetchPolzaLink, status: fetchPolzaLinkStatus } = useGetPolzaLink()

  const hasPointsWidget =
    data && data.type === ResponseType.ResolveWithData && data.result.enablePolzaPointsExchange

  const hasFreeCategoriesWidget =
    data && data.type === ResponseType.ResolveWithData && data.result.enablePolzaFreeCategories

  const polzaButtonOnClick = () => {
    let polzaLink = DEFAULT_POLZA_LINK
    fetchPolzaLink({
      apiVersion: context.config.apiVersion,
    })
      .then((result) => {
        if (
          result.response.type === ResponseType.ResolveWithData &&
          result.response.data.polzaLink
        ) {
          polzaLink = result.response.data.polzaLink
        }
      })
      .finally(() => {
        window.open(polzaLink, '_blank')
      })
  }

  return (
    <>
      {hasPointsWidget && (
        <PointsWidget
          maskCardNumber={maskCardNumber}
          onClick={() => {
            changeContent({
              type: PartnersContentType.ExchangePoints,
              title: MODAL_TITLES.exchangePoints,
              month: null,
              subtitle: null,
            })
          }}
        />
      )}
      {hasFreeCategoriesWidget && (
        <FreeCategoriesWidget changeContent={changeContent} maskCardNumber={maskCardNumber} />
      )}
      <PolzaButton onClick={polzaButtonOnClick} isLoading={fetchPolzaLinkStatus === 'loading'} />
    </>
  )
})
