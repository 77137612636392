import {
  CategoriesShimmerWrapper,
  CategoryIconShimmer,
  DescriptionShimmer,
  Root,
  TitleShimmer,
} from './styled'

export const FreeCategoriesShimmer = () => (
  <Root>
    <TitleShimmer />
    <DescriptionShimmer />
    <CategoriesShimmerWrapper>
      <CategoryIconShimmer />
      <CategoryIconShimmer />
      <CategoryIconShimmer />
    </CategoriesShimmerWrapper>
  </Root>
)
