import { CardsModel } from '@/api/partners/cards/CardsModel'
import { ProductsModel, useProductsQuery } from '@/api/products/products'
import { ApiStatus } from '@/core'
import { ResponseType } from '@/models/ResponseType'
import { UseQueryResult } from 'react-query'
import { createSelector } from 'reselect'
import { SystemError } from '@/models/SystemError'
import { CardsForExchange } from '../models'
import { useCardsForExchangeQuery } from '@/api/partners/cards'

type State = {
  products: UseQueryResult<ProductsModel.ResponseData, ProductsModel.ErrorResponse>
  cardsForExchange: UseQueryResult<CardsModel.ResponseData, CardsModel.ErrorResponse>
}

export const cardsForExchangeSelector = createSelector(
  (state: State) => state.products.data,
  (state: State) => state.products.status,
  (state: State) => state.products.refetch,
  (state: State) => state.cardsForExchange.data,
  (state: State) => state.cardsForExchange.status,
  (state: State) => state.cardsForExchange.refetch,
  (
    productsResponse,
    productsStatus,
    productsRefetch,
    cardsForExchangeResponse,
    cardsForExchangeStatus,
    cardsForExchangeRefetch
  ): {
    retry: () => void
    hasAnyResult: boolean
    error: SystemError<'NETWORK' | 'PARTIAL_RESPONSE' | 'IS_EMPTY_PRODUCTS'> | null
  } & (
    | {
        status: ApiStatus.Idle | ApiStatus.Pending
        cards: any
        cardsForExchange: any
      }
    | {
        status: ApiStatus.Rejected
        cards: any
        cardsForExchange: any
        error: SystemError<'NETWORK' | 'PARTIAL_RESPONSE' | 'IS_EMPTY_PRODUCTS'>
      }
    | {
        status: ApiStatus.Fulfilled
        cards: CardsForExchange
        cardsForExchange: CardsModel.CardForExchange[]
      }
  ) => {
    const retryFns: (() => void)[] = []

    let cards: (ProductsModel.DebitCard | ProductsModel.CreditCard)[] = []

    let cardsForExchange: CardsModel.CardForExchange[] = []

    let hasNetworkError = false
    let hasPartialResponse = false
    let status = ApiStatus.Idle

    if (
      productsStatus === 'success' ||
      productsStatus === 'error' ||
      cardsForExchangeStatus === 'success' ||
      cardsForExchangeStatus === 'error'
    ) {
      status = ApiStatus.Fulfilled
    }

    if (productsStatus === 'error' && cardsForExchangeStatus === 'error') {
      status = ApiStatus.Rejected
    }

    if (productsStatus === 'loading' || cardsForExchangeStatus === 'loading') {
      status = ApiStatus.Pending
    }

    if (productsStatus === 'error' || cardsForExchangeStatus === 'error') {
      hasNetworkError = true
    }

    if (productsStatus === 'error') {
      retryFns.push(productsRefetch)
    }

    if (cardsForExchangeStatus === 'error') {
      retryFns.push(cardsForExchangeRefetch)
    }

    if (productsResponse) {
      if (productsResponse.type === ResponseType.ResolveWithData) {
        cards = cards.concat(
          ...productsResponse.products.filter(
            (product) =>
              (product.productType === ProductsModel.ProductType.DebitCard &&
                product.cardStatusDisplayed !== ProductsModel.CardStatusDisplayed.Unknown) ||
              product.productType === ProductsModel.ProductType.CreditCard
          )
        )
      } else {
        hasPartialResponse = true
        retryFns.push(productsRefetch)
      }
    }

    if (cardsForExchangeResponse) {
      if (cardsForExchangeResponse.type === ResponseType.ResolveWithData) {
        cardsForExchange = cardsForExchangeResponse.data

        const cardsIdForExchange = cardsForExchangeResponse.data.map((item) => item.maskCardNumber)

        cards = cards.filter((item) => {
          return cardsIdForExchange.includes(item.maskCardNumber)
        })
      } else {
        hasPartialResponse = true
        retryFns.push(cardsForExchangeRefetch)
      }
    } else {
      cards = []
    }

    const error: SystemError<'NETWORK' | 'PARTIAL_RESPONSE' | 'IS_EMPTY_PRODUCTS'> | null =
      hasNetworkError
        ? {
            type: 'NETWORK',
            message: 'При получении данных произошла ошибка',
          }
        : hasPartialResponse
        ? {
            type: 'PARTIAL_RESPONSE',
            message: 'Ведутся сервисные работы',
          }
        : status === ApiStatus.Fulfilled && !cards.length
        ? {
            type: 'IS_EMPTY_PRODUCTS',
            message: 'Список продуктов пуст',
          }
        : null

    const hasCards = cards.length > 0

    return {
      error: error,
      cards,
      status,
      retry: () => {
        retryFns.forEach((item) => {
          item()
        })
      },
      cardsForExchange,
      hasAnyResult: hasCards || !!error,
    }
  }
)

export function useCardsForExchangeSelector(maskCardNumber: string) {
  const products = useProductsQuery()

  const cardsForExchange = useCardsForExchangeQuery({
    maskCardNumber,
  })

  return cardsForExchangeSelector({
    products: products,
    cardsForExchange: cardsForExchange,
  })
}
