import { useQuery, UseQueryOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { PointsModel } from './PointsModel'
import { adapter } from './adapters/adapter'

export const points: PointsModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.partners.points,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.get<
    PointsModel.ResponseData,
    PointsModel.Props,
    PointsModel.RequestHeaders,
    PointsModel.ErrorResponse
  >({
    url: path,
    headers: null,
    query: {
      maskCardNumber: props.maskCardNumber,
      contractNumber: props.contractNumber,
    },
    responseAdapter: adapter,
    control,
  })

  return response
}

function pointsKey(props: PointsModel.Props) {
  return ['/api/partners/points', ...Object.values(props)]
}

export function usePointsQuery<T extends PointsModel.ResponseData = PointsModel.ResponseData>(
  props: PointsModel.Props,
  options: Omit<
    UseQueryOptions<PointsModel.ResponseData, PointsModel.ErrorResponse, T>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const key = pointsKey(props)
  const result = useQuery(key, () => points(props).then((res) => res.response), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    ...options,
  })

  return result
}
