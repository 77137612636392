import { observer } from 'mobx-react-lite'
import { Controller, useFormContext } from 'react-hook-form'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { CardsForExchange } from '../../models'
import { selectCardsItemsSelector } from '../../selectors'
import { FormValues } from '../ExchangePointsForm/models'
import { SelectCardsForExchange, SelectProductsContainer, SelectTitle } from './styled'
import { EmptyCardsMessage } from '../../components/EmptyCardsMessage'

export type CardSelectProps = Readonly<{
  cards: CardsForExchange
}>

/**
 * Выпадающий список карт, для которых доступен обмен баллов
 */

export const CardSelect = observer<CardSelectProps>(({ cards }) => {
  const { config } = useAppContext<AppContext>()

  const isMobile = config.device.isMobile

  const { control, setValue, watch } = useFormContext<FormValues>()

  const items = selectCardsItemsSelector({ cards })

  const selectedCard = watch('maskCardNumber')

  if (!cards.length) {
    return <EmptyCardsMessage />
  }
  return (
    <SelectProductsContainer>
      <SelectTitle>Куда</SelectTitle>
      <Controller
        name="maskCardNumber"
        control={control}
        render={() => (
          <SelectCardsForExchange
            selectedId={selectedCard}
            items={items}
            isMobile={isMobile}
            onSelect={(item) => {
              setValue('maskCardNumber', item.id)
            }}
          />
        )}
      />
    </SelectProductsContainer>
  )
})
