import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { usePointsQuery } from '@/api/partners/points'
import { ResponseType } from '@/models/ResponseType'
import { PartnersError } from '@/features/partners/components/PartnersError'
import { PointsPlate } from '../../components/PointsPlate'
import { PointsShimmer } from '../../components/PointsShimmer'

export type PointsWidgetProps = Readonly<{
  maskCardNumber: string
  className?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}>

export const PointsWidget: FC<PointsWidgetProps> = observer((props) => {
  const { maskCardNumber, onClick } = props

  const { status, data, error, refetch } = usePointsQuery(
    {
      maskCardNumber,
    },
    {
      refetchOnMount: 'always',
    }
  )

  if (status === 'loading') {
    return <PointsShimmer />
  }

  if (status === 'error') {
    return (
      <PartnersError
        message={error.response.systemErrors?.[0]?.message || 'Не удалось загрузить баллы «Пользы»'}
        retryClick={() => refetch()}
      />
    )
  }

  if (status === 'success' && data.type === ResponseType.ResolveWithData) {
    const { currentBalance, pointsToBurn, pointsToBe, isExchangePossible, polzaInfo } = data.data

    return (
      <PointsPlate
        currentBalance={currentBalance}
        minPointsSumExchange={polzaInfo.minSumExchange}
        pointsToBurn={pointsToBurn}
        pointsToBe={pointsToBe}
        isExchangePossible={isExchangePossible}
        bgColorTheme={isExchangePossible ? 'dark' : 'light'}
        onClick={isExchangePossible ? onClick : null}
      />
    )
  }
  return null
})
