import React from 'react'
import { CircularProgress } from '@platform-ui/components'
import { Container } from './styled'

export const ExchangePointsLoader: React.FC = () => {
  return (
    <Container>
      <CircularProgress />
    </Container>
  )
}
