import React from 'react'
import { observer } from 'mobx-react-lite'
import { FormProvider } from 'react-hook-form'
import { ApiStatus } from '@/core'
import { amountStringToNumber } from '@/utils'
import { ResponseType } from '@/models/ResponseType'
import { usePointsQuery } from '@/api/partners/points'
import { CardsModel } from '@/api/partners/cards/CardsModel'
import {
  ErrorResultContent,
  ExchangePointsResult,
} from '@/features/partners-modal/components/ExchangePointsResult'
import { ExchangePointsLoader } from '@/features/partners-modal/components/ExchangePointsLoader'
import { maskCardNumberForExchangeByDefault } from '../../utils'
import { useCardsForExchangeSelector } from '../../selectors'
import { ModalContent } from '../../models'
import { ExchangePointsForm, ExchangePointsFormShimmer, useFormState } from '../ExchangePointsForm'

export type ExchangePointsProps = Readonly<{
  maskCardNumber: string
  setContent: React.Dispatch<React.SetStateAction<ModalContent>>
  resetContent: () => void
  setIsBackButtonShown: React.Dispatch<React.SetStateAction<boolean>>
}>

export const ExchangePoints = observer<ExchangePointsProps>((props) => {
  const { maskCardNumber, setContent, resetContent, setIsBackButtonShown } = props

  const {
    data: pointsData,
    status: pointsQueryStatus,
    isSuccess: isPointsDataSuccess,
    refetch: refetchPointsQuery,
    isRefetching: isPointsQueryRefetching,
  } = usePointsQuery({
    maskCardNumber,
  })

  const isResolvePointsWithData =
    isPointsDataSuccess && pointsData.type === ResponseType.ResolveWithData

  const {
    status: cardsForExchangeStatus,
    cards,
    cardsForExchange,
    error: cardsForExchangeSelectorError,
    retry: retryCardsForExchangeSelector,
  } = useCardsForExchangeSelector(maskCardNumber)

  const maskCardNumberByDefault = maskCardNumberForExchangeByDefault(cards, maskCardNumber)

  const { form, pointsExchangeMutation, handleValidSubmit } = useFormState(
    isResolvePointsWithData ? pointsData.data.currentBalance : 0,
    maskCardNumberByDefault,
    cardsForExchange,
    isResolvePointsWithData ? pointsData.data.polzaInfo.minSumExchange : 0
  )

  const { watch } = form

  const [amount, maskCardNumberForExchange] = watch(['amount', 'maskCardNumber'])

  const selectedCardForExchange: CardsModel.CardForExchange = cardsForExchange?.find(
    (item) => item.maskCardNumber === maskCardNumberForExchange
  )

  const formattedAmount = amountStringToNumber(amount)

  const isResolvePointsExchangeWithData =
    pointsExchangeMutation.data?.data.type === ResponseType.ResolveWithData

  if (
    cardsForExchangeStatus === ApiStatus.Pending ||
    pointsQueryStatus === 'loading' ||
    isPointsQueryRefetching
  ) {
    return <ExchangePointsFormShimmer />
  }

  if (pointsExchangeMutation.status === 'loading') {
    return <ExchangePointsLoader />
  }

  if (pointsQueryStatus === 'error') {
    return <ErrorResultContent onRetryButtonClick={refetchPointsQuery} />
  }

  if (
    (cardsForExchangeSelectorError?.type === 'PARTIAL_RESPONSE' && !cards.length) ||
    cardsForExchangeSelectorError?.type === 'NETWORK'
  ) {
    return <ErrorResultContent onRetryButtonClick={retryCardsForExchangeSelector} />
  }

  if (pointsExchangeMutation.status === 'success' || pointsExchangeMutation.status === 'error') {
    return (
      <ExchangePointsResult
        amount={formattedAmount}
        selectedMaskCardNumberForExchange={maskCardNumberForExchange}
        cardEnrollment={selectedCardForExchange?.enrolment || ''}
        oldPointsSum={isResolvePointsWithData ? pointsData.data.currentBalance : 0}
        newPointsSum={
          isResolvePointsExchangeWithData ? pointsExchangeMutation.data.data.data.points : 0
        }
        cards={cards}
        status={pointsExchangeMutation.status}
        setContent={setContent}
        resetContent={resetContent}
        resetMutation={pointsExchangeMutation.reset}
        setIsBackButtonShown={setIsBackButtonShown}
      />
    )
  }

  return (
    <FormProvider {...form}>
      <ExchangePointsForm
        form={form}
        pointsData={pointsData}
        cards={cards}
        selectedCardEnrolment={selectedCardForExchange?.enrolment || ''}
        handleValidSubmit={handleValidSubmit}
      />
    </FormProvider>
  )
})
