import React, { forwardRef } from 'react'
import { Root, Container, SelectCategoriesButton, PrimaryText, SecondaryText } from './styled'
import { pluralize } from '@/utils'
import { CATEGORIES_TITLES } from '../../constants'

export type FreeCategoriesPlateProps = Readonly<{
  className?: string
  onClick?: () => void
  month: string
  maxSelectedPositions: number
  cashbackPercentText: string
}>

export const FreeCategoriesPlate = forwardRef<HTMLDivElement, FreeCategoriesPlateProps>(
  (props, ref) => {
    const { month, maxSelectedPositions, cashbackPercentText, className, onClick, ...otherProps } =
      props

    return (
      <Root ref={ref} className={className} {...otherProps}>
        <Container>
          <PrimaryText>Повышенный кэшбэк на {month}</PrimaryText>
          <SecondaryText>Категории кэшбэка {cashbackPercentText}</SecondaryText>
          <SelectCategoriesButton view="ghost" sizeSm={'m'} onClick={onClick}>
            {`Выбрать  ${maxSelectedPositions} ${pluralize(
              maxSelectedPositions,
              CATEGORIES_TITLES
            )}`}
          </SelectCategoriesButton>
        </Container>
      </Root>
    )
  }
)
