import { ProductsModel } from '@/api/products/products'
import { CardsForExchange } from '../models'

export const maskCardNumberForExchangeByDefault = (
  cards: CardsForExchange,
  maskCardNumber: string
): string =>
  cards.reduce((acc: string, item, _, array) => {
    if (
      (item.productType === ProductsModel.ProductType.CreditCard ||
        item.productType === ProductsModel.ProductType.DebitCard) &&
      item.maskCardNumber === maskCardNumber
    ) {
      return item.maskCardNumber
    }

    if (acc.length > 0) {
      return acc
    }

    return array[0].maskCardNumber
  }, '')
