import styled from '@emotion/styled'
import { Button } from '@platform-ui/components'
import { ConvertationIcon } from '@platform-ui/icons'
import { Body14, Headline14, Headline16 } from '@platform-ui/typography'

export const Root = styled.div(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: theme.color['text/main'],
}))

export const Content = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const ProductNameBlock = styled.div(({ theme }) => ({
  marginBottom: theme.spacing.s4,
}))

export const AmountBlock = styled.div(({ theme }) => ({
  marginBottom: theme.spacing.s16,
  textAlign: 'center',
}))

export const EnrollmentPeriodText = styled(Headline14)(({ theme }) => ({
  color: theme.color['text/secondary'],
}))

export const IconContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.color['icons/colors/transfers'],
  width: '56px',
  height: '56px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing.s16,

  [theme.media.lg]: {
    width: '80px',
    height: '80px',
  },
}))

export const ConvertationIconStyled = styled(ConvertationIcon)(({ theme }) => ({
  width: '26px',
  height: '26px',
  color: theme.color['icons/shape'],

  [theme.media.lg]: {
    width: '37px',
    height: '37px',
  },
}))

export const BalanceHistoryBlock = styled.div(() => ({
  textAlign: 'center',
}))

export const BalanceHistoryTitle = styled(Body14)(({ theme }) => ({
  marginBottom: theme.spacing.s2,
}))

export const BalanceHistoryIconContainer = styled.div(({ theme }) => ({
  textAlign: 'center',
  rotate: '180deg',
  margin: theme.spacing.s2,
}))

export const BalanceHistoryText = styled(Headline16)(() => ({
  display: 'flex',
  alignItems: 'center',
}))

export const ButtonStyled = styled(Button)(() => ({
  width: '100%',
}))

export const Dot = styled.span(({ theme }) => ({
  ':before': {
    content: '""',
    display: 'inline-block',
    width: '4px',
    height: '4px',
    borderRadius: '50%',
    marginBottom: '3px',
    marginLeft: theme.spacing.s4,
    marginRight: theme.spacing.s4,
    background: theme.color['text/main'],
  },

  [`${theme.media.md}`]: {
    ':before': {
      width: '6px',
      height: '6px',
    },
  },
}))
