import { forwardRef } from 'react'
import { Icon, Info, ItemWrapper, Name, TooltipBody } from './styled'
import { Checkbox } from '@platform-ui/components/Checkbox'
import { Tooltip } from '@platform-ui/components'
import { CheckIcon } from '@platform-ui/icons'

export interface CategoryItemProps {
  iconUrl: string
  title: string
  description: string
  isSelected: boolean
  disabled: boolean
  canSelect: boolean
  isChecked: boolean
  onClick: () => void
}

export const CategoryItem = forwardRef<HTMLLIElement, CategoryItemProps>((props, ref) => {
  const { iconUrl, title, description, isSelected, disabled, canSelect, isChecked, onClick } = props

  return (
    <ItemWrapper disabled={disabled} ref={ref}>
      <Icon src={iconUrl || ''} />
      <Name>{title}</Name>
      <Tooltip title={<TooltipBody>{description}</TooltipBody>} placement="bottom-end">
        <Info />
      </Tooltip>
      {canSelect &&
        (isSelected ? (
          <CheckIcon color="icons/colors/success" size="s12" />
        ) : (
          <Checkbox isDisabled={disabled} onChange={onClick} isChecked={isChecked} />
        ))}
    </ItemWrapper>
  )
})
