import React from 'react'
import { Root, TextPrimary } from './styled'

export const EmptyCardsMessage = () => {
  return (
    <Root>
      <TextPrimary>Нет доступных продуктов для перевода</TextPrimary>
    </Root>
  )
}
