import React from 'react'
import { observer } from 'mobx-react-lite'
import Image from '@/next/image'
import Error from '@/assets/Error.png'
import { Root, Content, ImageContainer, ErrorTitle, ErrorText, ButtonStyled } from './styled'

interface ErrorResultContentProps {
  onRetryButtonClick: () => void
}

export const ErrorResultContent = observer<ErrorResultContentProps>((props) => {
  const { onRetryButtonClick } = props

  return (
    <Root>
      <Content>
        <ImageContainer>
          <Image src={Error} width={108} height={108} alt="Error" />
        </ImageContainer>
        <ErrorTitle>Произошла ошибка</ErrorTitle>
        <ErrorText>Попробуйте снова или повторите попытку позже</ErrorText>
      </Content>
      <ButtonStyled onClick={onRetryButtonClick}>Попробовать еще раз</ButtonStyled>
    </Root>
  )
})
