import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useState } from 'react'
import { CategoriesList, LinksWrapper } from './styled'
import { useFreeCategoriesQuery } from '@/api/partners/freeCategories'
import { ResponseType } from '@/models/ResponseType'
import { CategoryItem } from '../../components/CategoryItem'
import { pluralize } from '@/utils'
import { formatDate } from '@/utils/formatDate'
import { SelectCategoriesHeader } from '../../components/SelectCategoriesHeader'
import { ConnectCategoriesButton } from '../../components/ConnectCategoriesButton'
import { DocumentTitle } from '@/components/DocsLink/styled'
import { DocsLink } from '@/components/DocsLink'
import { CATEGORIES_TITLES } from '../../constants'
import { useSetFreeCategories } from '@/api/partners/setFreeCategories'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'

type SelectedCategories = Array<{
  id: string
  isSpecial: boolean
}>

const PARTIALLY_CONNECTED_CATEGORIES_ERROR = 417

interface SelectCashbackCategoriesProps {
  maskCardNumber: string
  month: number
  resetContent: () => void
}

export const SelectCashbackCategories = observer<SelectCashbackCategoriesProps>((props) => {
  const { maskCardNumber, month, resetContent } = props
  const { eventBus } = useAppContext<AppContext>()

  const [checkedCategories, setCheckedCategories] = useState<SelectedCategories>([])
  const { isSuccess, data, refetch } = useFreeCategoriesQuery({
    maskCardNumber,
  })
  const {
    mutate: setCategories,
    error: setCategoriesError,
    isLoading: isSetCategoriesLoading,
    isError: isSetCategoriesError,
    isSuccess: isSetCategoriesSuccess,
  } = useSetFreeCategories()

  if (!isSuccess || data.type !== ResponseType.ResolveWithData) {
    return null
  }

  const selectedMonthData = data.data.find((item) => item.monthNumber === month)
  const {
    categories,
    maxSelectedPositions,
    selectedPositions,
    cashbackPercentText,
    termsUrl,
    categoriesDescriptionUrl,
  } = selectedMonthData

  const availableCount = maxSelectedPositions - selectedPositions
  const monthName = formatDate(new Date(null, month - 1), 'D MMMM').slice(2)

  const generalHeader = `Повышенный кэшбэк ${cashbackPercentText} до конца ${monthName}`
  const choiceHeader = `Выберите ${
    availableCount < maxSelectedPositions ? 'ещё ' : ''
  }${availableCount} ${pluralize(availableCount, CATEGORIES_TITLES)}`

  const header = availableCount === 0 ? generalHeader : choiceHeader
  const subHeader = !!availableCount && `Повышенный кэшбэк до конца ${monthName}`

  const connectedCategories = categories.filter((category) => category.isSelected)
  const filteredCategories = !!availableCount ? categories : connectedCategories

  const onCategoryClick = (id: string, isSpecial: boolean, isChecked: boolean) => {
    if (isChecked) {
      setCheckedCategories((prev) => prev.filter((item) => item.id !== id))
    } else {
      setCheckedCategories((prev) => [...prev, { id, isSpecial }])
    }
  }

  const [isConnectButtonAvailalbe, setIsConnectButtonAvailable] = useState(true)

  const onConnectButtonClick = useCallback(() => {
    setIsConnectButtonAvailable(false)

    setCategories({
      maskCardNumber,
      monthNumber: month,
      categoryId: checkedCategories.map((item) => item.id),
    })
  }, [checkedCategories, maskCardNumber, month, setCategories])

  useEffect(() => {
    if (isSetCategoriesLoading) {
      eventBus.connectCategoriesResult.open.emit({
        header: 'Подключаем категории',
      })
    }

    if (isSetCategoriesError) {
      const error = setCategoriesError.response?.systemErrors?.[0]
      eventBus.connectCategoriesResult.error.emit({
        header: (error?.type !== 'ADAPTER_ERROR' && error?.type) || 'Ошибка подключения категорий',
        text: error?.message || 'Не удалось подключить категории повышенного кэшбэка',
        showRetryButton: setCategoriesError.status !== PARTIALLY_CONNECTED_CATEGORIES_ERROR,
        onClose: () => {
          eventBus.connectCategoriesResult.close.emit()
          setIsConnectButtonAvailable(true)
        },
        onRetryClick: onConnectButtonClick,
      })

      if (setCategoriesError.status === PARTIALLY_CONNECTED_CATEGORIES_ERROR) {
        resetContent()
        refetch()
      }
    }

    if (isSetCategoriesSuccess) {
      resetContent()
      refetch()

      eventBus.connectCategoriesResult.success.emit({
        header: 'Категории подключены',
      })

      setTimeout(() => {
        eventBus.connectCategoriesResult.close.emit()
      }, 2000)
    }
  }, [
    eventBus.connectCategoriesResult,
    isSetCategoriesError,
    isSetCategoriesLoading,
    isSetCategoriesSuccess,
    onConnectButtonClick,
    refetch,
    resetContent,
    setCategoriesError,
  ])

  return (
    <>
      <SelectCategoriesHeader header={header} subHeader={subHeader} />
      <CategoriesList>
        {filteredCategories.map((category) => {
          const { id, cashbackPercent, name, isSelected, isSpecial, description, iconUrl } =
            category

          const isMaxCategoriesSelected =
            checkedCategories.length + connectedCategories.length === maxSelectedPositions &&
            !checkedCategories.find((item) => item.id === category.id) &&
            !category.isSelected &&
            availableCount !== 0

          const isNotSameTypes =
            checkedCategories[0]?.isSpecial !== undefined &&
            isSpecial !== checkedCategories[0].isSpecial

          const isDisabled =
            isNotSameTypes ||
            filteredCategories.some((item) => item.isSelected && item.isSpecial !== isSpecial) ||
            isMaxCategoriesSelected ||
            isSetCategoriesLoading

          const isChecked = checkedCategories.some((item) => item.id === id)

          return (
            <CategoryItem
              key={id}
              canSelect={!!availableCount}
              title={`${cashbackPercent}% ${name}`}
              iconUrl={iconUrl}
              description={description}
              isSelected={isSelected}
              disabled={isDisabled}
              isChecked={isChecked}
              onClick={() => onCategoryClick(id, isSpecial, isChecked)}
            />
          )
        })}
      </CategoriesList>
      <LinksWrapper>
        <DocumentTitle>Документы</DocumentTitle>
        <DocsLink url={termsUrl} title="Условия программы лояльности" />
        <DocsLink url={categoriesDescriptionUrl} title="Подробное описание категорий" />
      </LinksWrapper>
      {!!availableCount && (
        <ConnectCategoriesButton
          isDisabled={
            checkedCategories.length === 0 || isSetCategoriesLoading || !isConnectButtonAvailalbe
          }
          onClick={onConnectButtonClick}
        />
      )}
    </>
  )
})
