import { useQuery, UseQueryOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { CardsModel } from './CardsModel'
import { adapter } from './adapters/adapter'

export const cards: CardsModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.partners.cards,
    {} as ApiManagerModel.ApiOption
  )

  const response = await HttpClient.post<
    CardsModel.ResponseData,
    void,
    CardsModel.Props,
    CardsModel.RequestHeaders,
    CardsModel.ErrorResponse
  >({
    url: path,
    data: {
      maskCardNumber: props.maskCardNumber,
      contractNumber: props.contractNumber,
    },
    headers: null,
    responseAdapter: adapter,
    control,
  })

  return response
}

function cardsKey(props: CardsModel.Props) {
  return ['/api/partners/cards', ...Object.values(props)]
}

export function useCardsForExchangeQuery<
  T extends CardsModel.ResponseData = CardsModel.ResponseData
>(
  props: CardsModel.Props,
  options: Omit<
    UseQueryOptions<CardsModel.ResponseData, CardsModel.ErrorResponse, T>,
    'queryKey' | 'queryFn'
  > = {}
) {
  const key = cardsKey(props)
  const result = useQuery(key, () => cards(props).then((res) => res.response), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    ...options,
  })

  return result
}
