import React from 'react'
import { ChevronRight, Root, Body, IconWrapper, Loader } from './styled'
import { RubleCoinIcon } from '@platform-ui/icons'
import { ButtonBaseProps } from '@platform-ui/components'

interface PolzaButtonProps extends ButtonBaseProps {
  isLoading: boolean
}

export const PolzaButton: React.FC<PolzaButtonProps> = (props) => {
  const { onClick, isLoading, ...otherProps } = props
  return (
    <Root onClick={onClick} isDisabled={isLoading} {...otherProps}>
      <Body>
        <IconWrapper color="icons/tertiary">
          <RubleCoinIcon size="s12" />
        </IconWrapper>
        <span>Перейти в личный кабинет Пользы</span>
      </Body>
      {isLoading ? <Loader /> : <ChevronRight color="icons/main" />}
    </Root>
  )
}
