import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Headline16, Headline32 } from '@platform-ui/typography'
import { NavBackIcon } from '@platform-ui/icons'
import { formatDate } from '@/utils/formatDate'
import { currency } from '@/utils/currency'
import { CardsForExchange, ModalContent } from '@/features/partners-modal/models'
import {
  Root,
  Content,
  ButtonStyled,
  EnrollmentPeriodText,
  BalanceHistoryTitle,
  BalanceHistoryText,
  ProductNameBlock,
  AmountBlock,
  IconContainer,
  ConvertationIconStyled,
  BalanceHistoryBlock,
  BalanceHistoryIconContainer,
  Dot,
} from './styled'

interface SuccessResultContentProps {
  amount: number
  oldPointsSum: number
  newPointsSum: number
  cardEnrollment: string
  selectedMaskCardNumberForExchange: string
  cards: CardsForExchange
  setContent: React.Dispatch<React.SetStateAction<ModalContent>>
  resetContent: () => void
}

export const SuccessResultContent = observer<SuccessResultContentProps>((props) => {
  const {
    amount,
    oldPointsSum,
    newPointsSum,
    cardEnrollment,
    selectedMaskCardNumberForExchange,
    cards,
    setContent,
    resetContent,
  } = props

  const todayDate = formatDate(new Date(), 'DD.MM.YYYY')

  useEffect(() => {
    setContent((prevState) => {
      return { ...prevState, title: 'Обмен выполнен', subtitle: `${todayDate}` }
    })

    return () => {
      resetContent()
    }
  }, [])

  const selectedCard = cards.find(
    (card) => card.maskCardNumber === selectedMaskCardNumberForExchange
  )

  const formattedAmount = currency(amount, 'RUB').format()

  const formattedOldPointsSum = currency(oldPointsSum, null).format()
  const formattedNewPointsSum = currency(newPointsSum, null).format()

  return (
    <Root>
      <Content>
        <ProductNameBlock>
          <Headline16>
            {selectedCard?.productName || ''}
            <Dot />
            {selectedCard?.lastFourCardDigits || ''}
          </Headline16>
        </ProductNameBlock>
        <AmountBlock>
          <Headline32>{`+ ${formattedAmount}`}</Headline32>
          <EnrollmentPeriodText>{cardEnrollment}</EnrollmentPeriodText>
        </AmountBlock>
        <IconContainer>
          <ConvertationIconStyled />
        </IconContainer>
        <BalanceHistoryBlock>
          <BalanceHistoryTitle>Баланс баллов</BalanceHistoryTitle>
          <BalanceHistoryText>
            {formattedOldPointsSum}
            <BalanceHistoryIconContainer>
              <NavBackIcon size="s12" />
            </BalanceHistoryIconContainer>
            {formattedNewPointsSum}
          </BalanceHistoryText>
        </BalanceHistoryBlock>
      </Content>
      <ButtonStyled onClick={resetContent}>Закрыть</ButtonStyled>
    </Root>
  )
})
